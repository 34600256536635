import React from 'react';

import { ReserveWhiskeyPage } from '~/components/views/Whiskey/ReserveWhiskey/ReserveWhiskeyPage';
import AppContainer from '~/containers/AppContainer/AppContainer';
import Head from 'next/head';

const WhiskeyInvestmentPage: React.FC = () => {
    return (
        <AppContainer>
            <Head>
                <title>Invest in Whiskey</title>
                <meta
                    name={'description'}
                    content={
                        'Invest in whiskey with Vinovest. Our latest exit was a batch of high rye bourbon sold for 30.74% above their initial purchase price.'
                    }
                />
            </Head>
            <ReserveWhiskeyPage />
        </AppContainer>
    );
};

export default WhiskeyInvestmentPage;
